@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');

header{
    height: 100vh;
    padding-top: 5rem;
    overflow: hidden;
}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

.header__container h1{
    font-family: 'Berkshire Swash', cursive;
}

/*CTA*/
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* HEADER SOCIALS */
.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
    font-size: 1.4rem;
}

.header__socials::after{
    content: '';
    width: 1px;
    height: 3.5rem;
    background-color: var(--pink);
}

/* ME */
.me{
    background: linear-gradient(230deg, transparent, var(--background)), transparent;
    width: 22rem;
    height: 24rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 45% 45% 10% 10%;
    padding-top: 1.5rem;
    
}

/*SCROLL DOWN */
.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 500;
    font-size: 1rem;
    color: var(--pink);

}

/*ANIMATION*/
.animate__animated.animate__fadeInRight {
    --animate-duration: 2s;
  }

/* MEDIA QUERIES (MEDIUM) */ 
@media screen and (max-width: 1366px) {

header{
    height: 100vh;
    padding-top: 3rem;
    overflow: visible;
}

.me{
    width: 16rem;
    height: 18rem;
    left: calc(50% - 7.5rem);
} 

}

/* MEDIA QUERIES (SMALL) */ 
@media screen and (max-width: 600px) {
    .header{
        height: 50vh;
     }
     .header__socials, .scroll__down{
        display: none;
     }

     .me{
        width: 15rem;
        height: 17rem;
        position: absolute;
        left: calc(50% - (15rem/2));
        margin-top: 3rem;
        border-radius: 45% 45% 0 0;
        padding-top: 1.5rem;
     }

     header{
        height: 100vh;
        overflow: visible;
    }
    
    /*ANIMATION*/
    /* .animate__animated.animate__fadeInRight {
        animation: none;
        transition: none;
    } */
}
