/* change from portfolio to projects */
.projects__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.projects__item{
background: var(--background-secondary);
padding: 2rem;
border-radius: 1.5rem;
border: 1px solid transparent;
transition: var(--transition);
}

.projects__item:hover{
    background: var(--translucid);
    border-color: var(--pink);
    color: var(--text-pink)
}

.projects__item-image{
    border-radius: 1rem;
    overflow: hidden;
}

.projects__item h3{
    margin: 1.2rem 0 1rem;
}

.projects__item-cta{
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

/*ANIMATION*/

.animate__animated.animate__slideInUp {
    --animate-duration: 1s;
    --animate-delay: 0.4s;
  }
/* MEDIA QUERIES (MEDIUM) */ 
@media screen and (max-width: 1024px) {
   .projects__container{
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
   }
  
  }
  
  /* MEDIA QUERIES (SMALL) */ 
  @media screen and (max-width: 600px) {
   .projects__container{
    grid-template-columns: 1fr;
    gap: 1rem;
   }

   /* ANIMATION
   .animate__animated.animate__fadeInLeft {
    animation: none;
    transition: none;
} */
  }