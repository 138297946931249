.container.contact__container {
    width: 58%;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background-color: var(--translucid);
    border: 2px solid var(--background-secondary);
    resize: none;
    color: var(--text-dark);
    font-size: 0.9rem;
}

::placeholder{
  color: var(--text-secondary);
}

/*ANIMATION*/
.animate__animated.animate__fadeInUp{
  --animate-duration: 1.5s;
}

/* MEDIA QUERIES (MEDIUM) */ 
@media screen and (max-width: 1024px) {
  .container.contact__container{
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  }
  
  /* MEDIA QUERIES (SMALL) */ 
  @media screen and (max-width: 600px) {
    .container.contact__container{
        grid-template-columns: 1fr;
        width: var(--container-width-sm);
      }
      
    .contact__button{
      width: 100%;
    }

    /*ANIMATION*/
  /* .animate__animated.animate__fadeInUp{
        animation: none;
        transition: none;
  } */
  }