.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
}

.about__me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(45deg, transparent, var(--background)),
    transparent;
  display: grid;
  place-items: center;
}

.about__me-image {
  padding: 2.5rem;
  overflow: hidden;
  transform: rotate(0);
  transition: var(--transition);
}

.about__me-image:hover {
  transform: rotate(-15deg);
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.8rem;
}

.about__card {
  background: #5A2250;
  color: #CFCFCF;
  border: 1px solid transparent;
  border-radius: 2rem;
  padding: 2rem;
  text-align: center;
  width: 10rem;
}

.about__card:hover {
  background: var(--white);
  color: var(--text-pink);
  border: 1px solid var(--pink);
  transition: var(--transition);
  cursor: default;
}

.about__icon {
  color: var(--pink);
  font-size: 1.5rem;
  margin-bottom: 0.1rem;
}

.about__card h5 {
  font-size: 1rem;
  font-weight: 600;
}

.about__card small {
  font-size: 0.9em;
}

.about__content p {
  margin: 2rem 0 2.6rem;
  color: var(--text-secondary);
  font-size: 1rem;
  font-weight: 500;
}

/*ANIMATION*/
.animate__animated.animate__fadeInRight {
  --animate-duration: 2s;
  --animate-delay: 1s;
}


/* MEDIA QUERIES (MEDIUM) */
@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about__me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about__me-image {
    transform: none;
  }

  .about__content {
    margin: 1rem 0 1.5rem;
  }
}

/* MEDIA QUERIES (SMALL) */
@media screen and (max-width: 600px) {
  .about__me-image:hover {
    transform: none;
  }

  .about__me {
    width: 65%;
    margin: 0 auto 3rem;
  }

  .about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .about__content{
    text-align: center;
  }

.about__content p{
    margin: 1.5rem 0;
}

/*ANIMATION*/
/* .animate__animated.animate__fadeInRight {
  animation: none;
  transition: none;
} */
}
