.navbar{
    background: var(--translucid) ;
    width: max-content;
    display: block;
    padding: 1rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%) ;
    bottom: 2rem;
    display: flex;
    gap: 1rem;
    border-radius: 3rem;
    backdrop-filter: blur(5px);
}

.nav__item {
    background-color: transparent;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    color: var(--pink);
    font-size: 1.1rem;
}

.nav__item:hover{
    background: rgba(232, 46, 145, 0.534);
}

.nav__item.active{
    background-color: rgba(173, 34, 233, 0.3);
    color: var(--white);
}

