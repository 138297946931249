.experience__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience__container > div {
    background: var(--background);
    color: var(--text-dark);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
}

.experience__container > div:hover {
    background: var(--translucid);
    color: var(--text-pink);
    border: 1px solid var(--pink);
    cursor: default;
    transition: var(--transition);  
}

.experience__container > div h3 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: var(--text-pink);
    font-weight: 500;
    font-size: 1.3rem;
}

.experience__content {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    margin-left: auto;
    margin-right: auto;
}

.experience__work .experience__content{
    row-gap: 1rem;
}

.experience__details{
    display: flex;
    gap:1rem;
}

.experience__details-icon{
    margin-top: 6px;
    color: var(--text-pink);
}

.experience__details small {
    font-size: 0.9rem;
    font-weight: 400;
}

.experience__content li {
    list-style: circle;
}

/*ANIMATION*/
.animate__animated.animate__fadeInLeft.animate__fadeInRight{
    --animate-duration: 1s;
}

/* MEDIA QUERIES (MEDIUM) */
@media screen and (max-width: 1024px) {
   .experience__container {
    grid-template-columns: 1fr;
   }

   .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
   }

   .experience__content{
    padding: 1rem 2rem;
   }
  }
  
  /* MEDIA QUERIES (SMALL) */
  @media screen and (max-width: 600px) {
    .experience__container{
        gap: 1rem;
    }

    .experience__container > div{
        width: 100%;
        padding: 2rem 1rem;
    }

    /*ANIMATION*/
    /* .animate__animated.animate__fadeInLeft.animate__fadeInRight{
        animation: none;
        animation-duration: 0s;
        transition: none;
        transition-property: none;
        --animate-repeat: 0;
        --animate-duration: 0s;
        --animate-delay: 0s;
        transform: none !important;
    } */
  }